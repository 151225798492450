import { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { times } from 'lodash'
import { InvestmentFullSizeCardLoading } from 'ui/components/content'

interface InvestmentsCarouselLoadingProps extends ComponentProps<'div'> {
  slidesToShow: number
}

export const InvestmentsCarouselLoading: FC<InvestmentsCarouselLoadingProps> = ({
  className,
  slidesToShow,
  ...props
}) => {
  return (
    <div className={clsx('flex h-[222px] w-full items-start gap-4', className)} {...props}>
      {times(slidesToShow, (i) => (
        <InvestmentFullSizeCardLoading key={i} className="w-full sm:w-[276px]" />
      ))}
    </div>
  )
}

import { FC, useState } from 'react'
import { useSession } from 'auth'
import clsx from 'clsx'
import { useFlag, LDFlag } from 'launchdarkly'
import { DefaultLayout } from '@perry/app/components/layouts'
import { AmityFeed } from './AmityFeed'
import { Announcements } from './Announcements'
import { Events } from './Events'
import { Groups } from './Groups'
import { Logo, NominateMember, SearchContainer } from './Header'
import { Investments } from './Investments'
import { Nominations } from './Nominations'
import { NominatorsLeaderboard } from './NominatorsLeaderboard'
import { MyProfileDesktop, MyProfileMobile } from './Profile'

export const Main: FC = () => {
  const displayNominatorsLeaderboard = useFlag(LDFlag.DisplayNominatorsLeaderboard)

  const [isNominatedMembersLoading, setIsNominatedMembersLoading] = useState(true)

  // ToDo: useState for isNominationsLeaderboardLoading should be set to true
  // when the displayNominatorsLeaderboard removed. The current setting
  // preventing Nominators Leaderboard from affecting the rest of the page.
  const [isNominationsLeaderboardLoading, setIsNominationsLeaderboardLoading] = useState(false)
  const [isProfileLoading, setIsProfileLoading] = useState(true)
  const [isInvestmentsLoading, setInvestmentsLoading] = useState(true)
  const [isEventsLoading, setEventsLoading] = useState(true)

  const { user, isLoading: isUserLoading } = useSession()

  // Sync loading state
  const isLoading =
    isProfileLoading ||
    isNominatedMembersLoading ||
    isNominationsLeaderboardLoading ||
    isUserLoading ||
    isInvestmentsLoading ||
    isEventsLoading

  return (
    <DefaultLayout
      className={clsx(
        'mx-auto flex flex-col gap-4 px-4 py-6',
        'sm:max-w-[632px] sm:py-8',
        'w-full xl:grid xl:grid-rows-[min-content_auto]',
        'xl:max-w-7xl xl:grid-cols-4 xl:px-8',
      )}
      title="meetperry | Home"
      banner={
        <MyProfileMobile
          onReady={() => setIsProfileLoading(false)}
          isLoading={false}
          userId={user.id}
        />
      }
    >
      <Logo className={'hidden shrink-0 xl:flex'} />
      <div className="col-span-3 col-start-2 hidden items-center md:flex md:justify-between md:gap-4 xl:justify-end">
        <SearchContainer className="h-10 md:w-full xl:w-[172px]" />
        <NominateMember />
      </div>

      {/* Left Column */}
      <div className="col-start-1 flex flex-col gap-4">
        <MyProfileDesktop
          className="hidden sm:block xl:self-stretch"
          userId={user.id}
          isLoading={isLoading}
          onReady={() => setIsProfileLoading(false)}
        />
        <Nominations
          userId={user.id}
          isLoading={isLoading}
          onReady={() => setIsNominatedMembersLoading(false)}
        />
        {displayNominatorsLeaderboard && (
          <NominatorsLeaderboard
            isLoading={isLoading}
            onReady={() => setIsNominationsLeaderboardLoading(false)}
            className="hidden sm:block"
          />
        )}
      </div>
      {/* Middle (Main) Column */}
      <div className="col-span-2 flex flex-col gap-y-4">
        {/**
         * We intentionally do not handle announcements ready state
         * since this section is optional and should not affect the rest of the page
         */}
        <Announcements isLoading={isLoading} />
        <Investments isLoading={isLoading} onReady={() => setInvestmentsLoading(false)} />
        {/**
         * We intentionally do not handle AmityFeed ready state
         * and make its loading state independent
         * since this section is backed by third party service
         * and should not affect the rest of the page
         */}
        <AmityFeed />
      </div>
      {/* Right Column */}
      <div className="flex flex-col gap-4 xl:col-start-4">
        <Events onReady={() => setEventsLoading(false)} isLoading={isLoading} />
        {/**
         * Intentionally stopped using information regarding groups loading state
         * because it is provided by third party service Amity, slowest to load
         * and should not affect the rest of the page.
         * It speeds up the page load time about 3 times.
         */}
        <Groups isLoading={isLoading} />
      </div>
    </DefaultLayout>
  )
}

import { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { times } from 'lodash'
import { InvestmentFullSizeCardLoading } from 'ui/components/content'

export const InvestmentsLoading: FC<ComponentProps<'div'>> = ({ className, ...rest }) => {
  return (
    <div className={clsx('grid grid-cols-2 gap-4', className)} {...rest}>
      {times(4, (i) => (
        <InvestmentFullSizeCardLoading key={i} />
      ))}
    </div>
  )
}

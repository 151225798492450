import { ComponentProps, FC, useState } from 'react'
import clsx from 'clsx'
import { Carousel, InvestmentFullSizeCard } from 'ui/components/content'
import { InvestmentFullSizeCardProps } from 'ui/components/content/InvestmentFullSizeCard/InvestmentFullSizeCard'

interface InvestmentsCarouselProps extends ComponentProps<typeof Carousel> {
  investments: InvestmentFullSizeCardProps['investment'][]
  slidesToShow: number
}

export const InvestmentsCarousel: FC<InvestmentsCarouselProps> = ({
  investments,
  slidesToShow,
  ...props
}) => {
  const [slideIndex, setSlideIndex] = useState(0)

  return (
    <Carousel
      slidesToShow={slidesToShow}
      beforeSlide={(_, endSlideIndex) => {
        setSlideIndex(endSlideIndex)
      }}
      {...props}
    >
      {investments.map((investment, i) => (
        <InvestmentFullSizeCard
          key={investment.id}
          className={clsx(
            'block w-full sm:w-[276px]',
            // in case need to show more that one slide with some space between each
            // nuka carousel can't split free space between items automatically
            // take first showed slide and add growing relative shift for all next slides
            `relative`,
          )}
          style={{
            left: `${((i - slideIndex) % slidesToShow) * 8}px`,
          }}
          investment={investment}
        />
      ))}
    </Carousel>
  )
}

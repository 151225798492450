import { FC } from 'react'
import { Role } from 'api/dto'
import { Main } from '@perry/app/components/pages/main'
import { withAuthRequired } from '../lib/auth'

export const MainPage: FC = () => {
  return <Main />
}

export default withAuthRequired(MainPage, Role.INVESTOR)

import { FC } from 'react'
import Image from 'next/image'
import { Link } from 'ui/components/navigation'
import { cn } from 'ui/lib'

export const Logo: FC<{ className?: string }> = ({ className }) => (
  <Link href="/" className={cn('flex items-center', className)}>
    <div className="bg-deep-teal-500 relative mr-3 flex min-h-10 min-w-10 items-center justify-center rounded-lg">
      <Image src="/images/meetperry-mobile-white.svg" width={28} height={16} alt="mp" />
    </div>
    <h1 className="text-deep-teal-900 text-nowrap text-xl">Welcome to</h1>
    <Image
      className="pl-1.5 pt-1"
      src="/images/meetperry-deep-teal-dark.svg"
      width={100}
      height={18}
      alt=""
    />
  </Link>
)

import { ComponentProps, FC, PropsWithChildren, ReactElement } from 'react'
import clsx from 'clsx'
import { FoundingMemberLabel, FoundingMemberLabelTheme } from 'ui/components/content'

type ProfileTemplateProps = Pick<ComponentProps<'div'>, 'className'> & {
  avatar: ReactElement
  firstName: string | ReactElement
  lastName: string | ReactElement
  isFoundingMember?: boolean
}

export const ProfileTemplate: FC<PropsWithChildren<ProfileTemplateProps>> = ({
  avatar,
  firstName,
  lastName,
  isFoundingMember,
  className,
  children,
}) => (
  <div className={clsx('flex flex-col gap-4', className)}>
    <div className="flex items-center gap-3">
      <div className="h-14 w-14 shrink-0">{avatar}</div>
      <div
        className={clsx(
          'flex flex-1 flex-col space-y-1 overflow-hidden',
          'text-2xl font-medium leading-7',
        )}
      >
        {isFoundingMember && <FoundingMemberLabel theme={FoundingMemberLabelTheme.light} />}
        <div className="text-deep-teal-500 font-brand hidden truncate xl:block">{firstName}</div>
        <div className="text-deep-teal-500 font-brand hidden truncate xl:block">{lastName}</div>
        <div className="text-deep-teal-500 font-brand block truncate xl:hidden">
          {firstName} {lastName}
        </div>
      </div>
    </div>
    <div className="bg-deep-teal-100 h-px w-full"></div>
    {children}
  </div>
)

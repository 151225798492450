import { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { Card } from 'ui/components/content'

export const InvestmentsSectionLoading: FC<ComponentProps<typeof Card>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <Card className={clsx('flex flex-col gap-4 px-4 py-6', className)} {...props}>
      <div className="flex items-center justify-between">
        <div className="bg-slate-200 h-5 w-1/2 shrink-0 animate-pulse self-start" />
      </div>
      <div className="flex-1">{children}</div>
    </Card>
  )
}

import { FC } from 'react'
import { Button } from 'ui/src/components/forms'

export const NominateMember: FC = () => {
  return (
    <Button
      uiType="primary"
      href="/nominations"
      data-trackclick={JSON.stringify({
        action: 'Clicked Nominate Member - Nominations Leaderboard',
      })}
    >
      Nominate a member
    </Button>
  )
}

import { FC, useEffect } from 'react'
import { InvestmentOpportunityWithTotalIoi } from 'api/dto'
import { useAxios } from 'api/lib'
import { InvestmentFullSizeCard, withErrorBoundaryAndLogging } from 'ui/components/content'
import { InvestmentsCarousel } from './InvestmentsCarousel'
import { InvestmentsCarouselLoading } from './InvestmentsCarouselLoading'
import { InvestmentsEmptyState } from './InvestmentsEmptyState'
import { InvestmentsLoading } from './InvestmentsLoading'
import { InvestmentsSection } from './InvestmentsSection'
import { InvestmentsSectionLoading } from './InvestmentsSectionLoading'
import { MainPageComponentProps } from '../ComponentProps'
import { useSlidesToShow } from '../useSlidesToShow'

const MAX_INVESTMENTS_IN_COMPONENT = 4

const useInvestments = (limit = MAX_INVESTMENTS_IN_COMPONENT) => {
  // URL is the unique key for the internal SWR cache. If we move "limit" to params,
  // URL will exactly match the one on the All Investment Opportunities page. This leads to
  // caching response between these two pages. To avoid this we make "limit" part of the key.
  return useAxios<InvestmentOpportunityWithTotalIoi[]>(
    `/api/investment-opportunities?limit=${limit}`,
  )
}

const useInvestmentsCount = () => {
  return useAxios<number>('/api/investment-opportunities/count')
}

const InvestmentsImplementation: FC<MainPageComponentProps> = ({ isLoading, onReady }) => {
  const slidesToShow = useSlidesToShow()

  const { data: investments, isLoading: isInvestmentsLoading } = useInvestments()
  const { data: investmentsCount, isLoading: isInvestmentsCountLoading } = useInvestmentsCount()

  useEffect(() => {
    if (!isInvestmentsLoading && !isInvestmentsCountLoading) {
      onReady?.()
    }
  }, [isInvestmentsLoading, isInvestmentsCountLoading])

  const isReady = !isLoading && !isInvestmentsLoading && !isInvestmentsCountLoading

  return isReady ? (
    <InvestmentsSection count={investmentsCount}>
      {investments.length ? (
        <>
          <div className="hidden grid-cols-2 gap-4 xl:grid">
            {investments.map((investment) => (
              <InvestmentFullSizeCard
                className="block"
                key={investment.id}
                investment={investment}
              />
            ))}
          </div>
          <InvestmentsCarousel
            investments={investments}
            slidesToShow={slidesToShow}
            className="xl:hidden"
          />
        </>
      ) : (
        <InvestmentsEmptyState />
      )}
    </InvestmentsSection>
  ) : (
    <InvestmentsSectionLoading>
      <InvestmentsLoading className="hidden xl:grid" />
      <InvestmentsCarouselLoading slidesToShow={slidesToShow} className="xl:hidden" />
    </InvestmentsSectionLoading>
  )
}

export const Investments = withErrorBoundaryAndLogging(InvestmentsImplementation)

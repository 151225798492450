import { FC } from 'react'
import { UserSocialProfilePreview } from 'api/src/dto'
import { Link } from 'ui/components/navigation'

type ProfileNominatedByProps = {
  nominatedBy: UserSocialProfilePreview[]
}

export const ProfileNominatedBy: FC<ProfileNominatedByProps> = ({ nominatedBy }) => (
  <div className="flex flex-col gap-2">
    <div className="text-deep-teal-900 leading-1 text-base font-semibold">Nominated by</div>

    <div>
      {nominatedBy.map((nominated) => (
        <div key={nominated.userId}>
          <Link
            className="basic-text-sm leading-1 font-semibold text-orange-600 hover:text-orange-800"
            href={`/members/${nominated.userId}`}
          >
            {nominated.firstName} {nominated.lastName}
          </Link>
        </div>
      ))}
    </div>
  </div>
)

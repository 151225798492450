import { FC, useEffect } from 'react'
import { UserSocialProfile } from 'api/dto'
import { useAxios } from 'api/lib'
import {
  MemberSummaryLoading,
  MemberSummary,
  withErrorBoundaryAndLogging,
} from 'ui/components/content'
import { MainPageComponentProps } from '../../ComponentProps'

const useProfile = (userId: number) =>
  useAxios<UserSocialProfile>(`/api/users/${userId}/social-profile`)

type ProfileProps = MainPageComponentProps & {
  userId: number
}

export const MyProfileMobileImplementation: FC<ProfileProps> = ({ userId, isLoading, onReady }) => {
  const { data: profile, isLoading: isUserProfileLoading } = useProfile(userId)

  useEffect(() => {
    if (!isUserProfileLoading) {
      onReady?.()
    }
  }, [isUserProfileLoading])

  return (
    <div className="bg-taupe-200 sm:hidden">
      <div className="bg-deep-teal-700 rounded-b-lg px-4 py-6">
        {isLoading || isUserProfileLoading ? (
          <MemberSummaryLoading />
        ) : (
          <MemberSummary className="sm:hidden" profile={profile} />
        )}
      </div>
    </div>
  )
}

export const MyProfileMobile = withErrorBoundaryAndLogging(MyProfileMobileImplementation)

import { FC, useEffect } from 'react'
import clsx from 'clsx'
import {
  Card,
  NominatorsList,
  NominatorsListLoading,
  useNominatorsLeaderboard,
  withErrorBoundaryAndLogging
} from 'ui/components/content'
import { MainPageComponentProps } from '../ComponentProps'

const NominatorsLeaderboardImplementation: FC<MainPageComponentProps & { className?: string }> = ({
  className,
  isLoading,
  onReady,
}) => {
  const { isLoading: isNominatorsLoading, data: nominators } = useNominatorsLeaderboard()

  useEffect(() => {
    if (!isNominatorsLoading) {
      onReady?.()
    }
  }, [isNominatorsLoading])

  const isReady = !isNominatorsLoading && !isLoading
  return (
    <Card className={clsx('hidden sm:block', className)}>
      {isReady ? (
        <>
          <h3 className="text-deep-teal-900 mb-4 text-base font-bold leading-5">
            Nominations Leaderboard
          </h3>
          {nominators?.length > 0 && <NominatorsList nominators={nominators} />}
        </>
      ) : (
        <>
          <div className="mb-4 h-5 w-full animate-pulse bg-slate-200" />
          <NominatorsListLoading />
        </>
      )}
    </Card>
  )
}

export const NominatorsLeaderboard = withErrorBoundaryAndLogging(
  NominatorsLeaderboardImplementation,
)

import { useMediaQuery } from 'ui/lib'

const NUMBER_OF_SLIDES_TO_SHOW_DEFAULT = 2
const NUMBER_OF_SLIDES_TO_SHOW_PHONE = 1

export function useSlidesToShow(): number {
  const isNotPhoneSize = useMediaQuery('(min-width: 640px)')
  const slidesToShow = isNotPhoneSize
    ? NUMBER_OF_SLIDES_TO_SHOW_DEFAULT
    : NUMBER_OF_SLIDES_TO_SHOW_PHONE

  return slidesToShow
}

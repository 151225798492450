import { FC, useEffect } from 'react'
import { useGroups } from 'amity/api/browser'
import {
  CardSection,
  GroupsCarousel,
  GroupsList,
  withErrorBoundaryAndLogging,
} from 'ui/components/content'
import { MainPageComponentProps } from '../ComponentProps'
import { useSlidesToShow } from '../useSlidesToShow'

const MAX_GROUPS_IN_THE_COMPONENT = 4

const GroupsImplementation: FC<MainPageComponentProps> = ({ isLoading, onReady }) => {
  const slidesToShow = useSlidesToShow()

  const { flatData, isLoading: isGroupsLoading } = useGroups(
    {
      filter: 'member',
      sortBy: 'lastCreated',
      isDeleted: false,
      options: { limit: MAX_GROUPS_IN_THE_COMPONENT },
    },
    { revalidateOnFocus: false },
  )

  useEffect(() => {
    if (!isGroupsLoading) {
      onReady?.()
    }
  }, [isGroupsLoading])

  return (
    <CardSection
      title="My groups"
      href={flatData?.length > 0 ? '/groups' : undefined}
      isLoading={isLoading}
    >
      <GroupsList
        groups={flatData}
        isLoading={isLoading || isGroupsLoading}
        className="hidden xl:flex"
      />
      <GroupsCarousel
        slidesToShow={slidesToShow}
        groups={flatData}
        isLoading={isLoading || isGroupsLoading}
        className="xl:hidden"
      />
    </CardSection>
  )
}

export const Groups = withErrorBoundaryAndLogging(GroupsImplementation)

import { FC } from 'react'
import { ProfileTemplate } from './ProfileTemplate'

export const ProfileLoadingState: FC = () => (
  <ProfileTemplate
    className="animate-pulse"
    avatar={<div className="h-full w-full rounded-full bg-slate-200"></div>}
    firstName={<div className="mb-1 h-4 w-full bg-slate-200" />}
    lastName={<div className="h-4 w-full bg-slate-200" />}
  >
    <div className="flex flex-1 flex-col gap-6">
      <div className="flex flex-col gap-1">
        <div className="h-4 w-full bg-slate-200" />
        <div className="h-4 w-full bg-slate-200" />
      </div>

      <div className="flex flex-col gap-1">
        <div className="h-4 w-full bg-slate-200" />
        <div className="h-4 w-full bg-slate-200" />
      </div>
    </div>
  </ProfileTemplate>
)

import { ComponentProps, FC } from 'react'
import clsx from 'clsx'

export const InvestmentsEmptyState: FC<ComponentProps<'div'>> = ({ className, ...props }) => (
  <div
    className={clsx(
      'bg-taupe-200 flex h-48 flex-col items-center justify-center rounded-lg p-6',
      className,
    )}
    {...props}
  >
    <img className="h-5 w-[102px]" src="/images/meetperry-taupe-600.svg" />
    <div className="text-taupe-600 mt-2 text-center text-sm font-semibold leading-5">
      Investment opportunity will come soon
    </div>
    <div className="text-taupe-600 mt-1 text-center text-xs leading-4">
      Investment opportunity will appear later, once become published
    </div>
  </div>
)

import { FC, useRef, useState } from 'react'
import { SocialSearch } from 'ui/components/navigation'
import { cn } from 'ui/lib'
export const SearchContainer: FC<{
  className?: string
}> = ({ className }) => {
  const resultContainerRef = useRef(null)
  const [isSocialSearchFocused, setIsSocialSearchFocused] = useState(false)
  return (
    <div className="relative flex flex-grow justify-end">
      {/* Container for search result should be rendered above other components */}
      <div className="absolute top-full z-10 w-full rounded-lg" ref={resultContainerRef}></div>
      <SocialSearch
        className={cn(
          'text-deep-teal-500',
          isSocialSearchFocused &&
            'flex-grow rounded-lg border border-gray-200 bg-white shadow-sm transition-all ',
          className,
        )}
        optionsClassName="rounded-lg"
        isFocused={isSocialSearchFocused}
        setIsFocused={setIsSocialSearchFocused}
        resultContainerRef={resultContainerRef}
      />
    </div>
  )
}
